<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-layout row justify-space-between align-center >
              <v-flex xs12 sm4 md5 lg5>
                <span class="heading">Training List</span>
              </v-flex>
              <v-flex xs12 sm4 md5 lg5 pt-2>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="Type To Search" class="text-des"
                  v-model="keyword"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md2 lg2 pt-2 pt-lg-0 pt-md-0 pt-sm-0 mt-2 class="text-right">
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="getExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel
                    <v-icon color="black">mdi-microsoft-excel</v-icon>
                  </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-flex xs12 v-if="items && items.length > 0">
              <v-layout wrap justify-start pt-2>
              </v-layout>
              <v-layout wrap justify-start pt-2>
                <v-flex xs12 v-if="items && items.length > 0">
                  <v-card>
                    <div style="overflow-y: auto; overflow-x: auto;">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablehead">Name</th>
                          <th class="text-left tablehead">Phone</th>
                          <th class="text-left tablehead">Email</th>
                          <th class="text-left tablehead">Action</th>
                          <th class="text-left tablehead">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td style="white-space: nowrap;">{{item.user.name}}</td>
                          <td style="white-space: nowrap;">{{item.user.phone}}</td>
                          <td style="white-space: nowrap;">{{ item.user.email }}</td>
                          <td style="white-space: nowrap;">
                           

                            <v-icon
                          small
                          color="success"
                          class="ml-4"
                          @click.stop="openviewdetails(item)"
                        >
                          mdi-eye
                        </v-icon>
                          </td>
                          <td style="white-space: nowrap;">
                          
                            <v-icon
                          small
                          color="error"
                          class="ml-4"
                          @click.stop="opendeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                          </td>
                          <!-- <td>
                            <v-btn
                              color="error"
                              depressed
                              small
                              @click="openrejectDialog(item)"
                              ><span>Reject</span></v-btn
                            >
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </div>
                  </v-card>
                </v-flex>
                <v-flex xs12 v-else>
                  <v-layout
                    wrap
                    justify-center
                    align-center
                   
                  >
                    <v-flex xs12 class="text-center">
                      <span class="nodata">No Data Found !</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="items && items.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
      
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to delete?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to reject?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="rejectoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
          <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        keyword: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        items: [],
        status:"",
        deleteDialog: false,
        deleteid:"",
        rejectDialog:false,
        rejectid:"",
      };
    },
    beforeMount() {
      this.getData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
    },
    methods: {
      openviewdetails(item) {
        this.$router.push({
          path: "/traininglistview",
          query: {
            id: item._id,
          },
        });
      },
      
    //   opendeleteDialog(item) {
    //     this.deleteid = item.operation._id;
    //     this.deleteDialog = true;
    //   },
      openrejectDialog(item) {
        this.rejectid = item._id;
        this.rejectDialog = true;
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/traininglist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params:{
            page:this.currentPage,
            count:this.count,

          }
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.items = response.data.data;
                this.Pagelength = response.data.pages;
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/traininglist/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteoperation() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/delete",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.deleteid,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Deleted Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.deleteDialog=false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog=false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      rejectoperation() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/removedeleterequest",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.rejectid,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Rejected Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.rejectDialog=false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog=false;

            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
          <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>